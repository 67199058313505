import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Tabs, Tab, Typography, CircularProgress, TextField, Button, Checkbox, FormControlLabel, Grid, Card, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import dayjs from 'dayjs';
import { GlobalContext } from '../context/GlobalContext';
import useApi from '../hooks/useApi'
import usePrevious from '../hooks/usePrevious'
import NiceModal from '@ebay/nice-modal-react';
import ModalContainer, { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer'
import { ConsumablesBox, DocumentChip, RegPlate, StatusChip } from './EnquiryItem';
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';

const EnquiryDetailsView = NiceModal.create(({ initialTab = 0, enquiry, currentUser }) => {
  const modalContainer = useRef(null)
  const [error, setError] = useState(null)
  const [tabIndex, setTabIndex] = useState(initialTab)

  const { isLoading: isLoadingLines, data: lines, refetch: refetchLines } = useApi({
    url: `https://vision-web-api-test.azurewebsites.net/api/connectplus/factor/response/${enquiry.EnquiryId}`
  })

  const { isLoading: isLoadingMessages, data: messages, refetch: refetchMessages } = useApi({
    url: `https://api.aa-vision.com/api/connectplus/factor/messages/${enquiry.EnquiryId}/false`,
    refetch: 20 * 1000
  })

  if (isLoadingLines || isLoadingMessages) return null

  const handleTabChange = (event, newIndex) => {
    if (newIndex === 0) refetchLines()
    if (newIndex === 1) refetchMessages()
    setTabIndex(newIndex);
  };

  const handleCloseModal = () => {
    console.log('closeModal')
    console.log(modalContainer.current)
    modalContainer.current?.closeModal()
  }

  const isLockedByCurrentUser = enquiry.Locked && enquiry.LockedUsername === currentUser;

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card sx={{ width: '900px' }}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <EnquiryHeader
            enquiry={enquiry}
            closeModal={handleCloseModal}
          />

          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Order Details" />
            <Tab label="Messages" />
          </Tabs>

          <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
            {
              error && (
                <Typography color="error">{error}</Typography>
              )
            }
            {
              tabIndex === 0 && lines && (
                <DetailsTab
                  lines={lines}
                />
              )
            }
            {
              tabIndex === 1 && messages && (
                <MessagesTab
                  messages={messages}
                  locked={enquiry.Locked}
                  isLockedByCurrentUser={isLockedByCurrentUser}
                />
              )
            }
          </Box>
        </Box>
      </Card>
    </ModalContainerAnimateDown>
  );
});

const EnquiryHeader = ({ enquiry, closeModal }) => {
  const isRegPlate = enquiry.Reg && enquiry.Reg !== 'CONSUMABLES';

  const today = dayjs().startOf('day');
  const createdDate = dayjs(enquiry.DatetimeCreated);
  const modifiedDate = dayjs(enquiry.DatetimeModified);

  const isCreatedToday = createdDate.isSame(today, 'day');
  const isModifiedToday = modifiedDate.isSame(today, 'day');

  const formattedCreatedDate = isCreatedToday
    ? createdDate.format('HH:mm')
    : createdDate.format('DD/MM/YYYY, HH:mm');
  const formattedModifiedDate = isModifiedToday
    ? modifiedDate.format('HH:mm')
    : modifiedDate.format('DD/MM/YYYY, HH:mm');

  const timeSinceCreated = createdDate.fromNow();
  const timeSinceModified = modifiedDate.fromNow();

  return (
    <Box sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', padding: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 1 }}>
            <StatusChip status={enquiry.Status} />
          </Box>
          <Box sx={{ paddingRight: 2 }}>
            <DocumentChip document={enquiry.Document} />
          </Box>
          {
            enquiry.Locked && (
              <Box sx={{ paddingRight: 2, color: 'orange' }}>
                <Tooltip title="This enquiry is locked.">
                  <LockOutlined />
                </Tooltip>
              </Box>
            )
          }
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {enquiry.CustomerKeyCode + ' - ' + enquiry.GarageName}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 2 }}>
            {
              isRegPlate ? (
                <RegPlate size={1.05}>{enquiry.Reg}</RegPlate>
              ) : (
                <ConsumablesBox />
              )
            }
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h7" sx={{ lineHeight: 1 }}>{enquiry.Parts}</Typography>
            <Typography variant="caption">{enquiry.Notes || 'No additional notes provided.'}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 1 }}>
        <Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Created:</span> {formattedCreatedDate} ({timeSinceCreated})
            </Typography>
          </Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Modified: </span>
              {
                !!enquiry.DatetimeModified ? (
                  `${formattedModifiedDate} (${timeSinceModified})`
                ) : (
                  'Never'
                )
              }
            </Typography>
          </Box>
        </Box>

        <Box>
          {
            enquiry.Locked && (
              <Box sx={{ display: 'inline-block', paddingRight: 2 }}>
                <Button variant="text" startIcon={<LockOpenOutlined />}>
                  Unlock
                </Button>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

const DetailsTab = ({ lines }) => {
  return (
    <Box sx={{ padding: 2, maxHeight: '50vh' }}>
      {/* Parts Ordered Table */}
      <Typography variant="h6" sx={{ mb: 2 }}>Parts Ordered</Typography>
      <Box sx={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', backgroundColor: '#f9f9f9' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>Desc</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>Part Number</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Qty</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'right' }}>Unit Price</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'right' }}>Cost</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line) => (
              <tr key={line.ResponseDetailId}>
                <td style={{ padding: '10px', textAlign: 'left' }}>{line.Parts}</td>
                <td style={{ padding: '10px', textAlign: 'left' }}>{line.PartNumber}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>{line.Qty}</td>
                <td style={{ padding: '10px', textAlign: 'right' }}>
                  {
                    line.Confirmed !== null ? (
                      `£${line.UnitPrice.toFixed(2)}`
                    ) : (
                      'Not confirmed.'
                    )
                  }
                </td>
                <td style={{ padding: '10px', textAlign: 'right' }}>
                  {
                    line.Confirmed !== null ? (
                      `£${line.Cost.toFixed(2)}`
                    ) : (
                      ''
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>

      {/* Delivery Date and Created Date */}
      <Typography variant="body1" sx={{ mt: 2 }}>
        <strong>Delivery Date:</strong> {dayjs(lines[0].DeliveryDate).format('DD/MM/YYYY')}
      </Typography>
      <Typography variant="body1">
        <strong>Created:</strong> {dayjs(lines[0].DatetimeCreated).format('DD/MM/YYYY, HH:mm')}
      </Typography>
    </Box>
  )
}

const MessagesTab = ({ messages, locked, isLockedByCurrentUser }) => {
  const [newMessage, setNewMessage] = useState('');

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, display: 'flex', flexDirection: 'column-reverse' }}>
        {messages && messages.length === 0 && (
          <Typography>No messages found for this enquiry.</Typography>
        )}

        {messages && [...messages].reverse().map((message) => (
          <Box
            key={message.MessageId}
            sx={{
              display: 'flex',
              justifyContent: message.Factor ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: '70%',
                p: 2,
                borderRadius: 2,
                backgroundColor: message.Factor ? 'dodgerblue' : '#e0e0e0',
                color: message.Factor ? 'white' : 'black',
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                {message.Username || 'Unknown'}
              </Typography>
              <Typography variant="body1">
                {message.Text || 'No message text available'}
              </Typography>
              <Typography variant="caption" sx={{ display: 'block', mt: 1, textAlign: 'right' }}>
                {message.Datetime ? dayjs(message.Datetime).format('DD/MM/YYYY HH:mm') : 'Unknown date'}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {!locked || isLockedByCurrentUser ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            borderTop: '1px solid #e0e0e0',
            backgroundColor: '#fafafa',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message..."
            value={newMessage}
            onChange={handleNewMessageChange}
            sx={{
              mr: 2,
              input: { color: 'black' },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={locked && !isLockedByCurrentUser}
          >
            Send
          </Button>
        </Box>
      ) : (
        <Typography variant="body2" sx={{ textAlign: 'center', color: 'gray' }}>
          You cannot send messages on a locked enquiry.
        </Typography>
      )}
    </Box>
  )
}

export default EnquiryDetailsView;
