import { useEffect, useState } from 'react'
import haxios from '../lib/haxios'

const useApi = ({ url, method = 'get', data: body, refetch = null }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(null)

  const loadData = async () => {
    let res
    setIsFetching(true)

    if (method.toLowerCase() === 'get') {
      res = await haxios.get(
        url,
        {
          params: body
        }
      )
    } else if (method.toLowerCase() === 'post') {
      res = await haxios.post(
        url,
        body
      )
    }

    setIsFetching(false)
    setIsLoading(false)
    setData(res)
  }

  useEffect(() => {
    console.log('loadeffect', body)
    loadData()
  }, [url, JSON.stringify(body)])

  useEffect(() => {
    if (!refetch) return

    const interval = setInterval(() => {
      console.log('interval')
      loadData()
    }, refetch)

    return () => clearInterval(interval)
  }, [refetch])

  return {
    isLoading,
    isFetching,
    data,
    refetch: loadData
  }
}

export default useApi