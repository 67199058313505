import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { GlobalProvider } from './context/GlobalContext';
import App from './App';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import NiceModal from '@ebay/nice-modal-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <NiceModal.Provider>
          <Router>
            <App />
          </Router>
        </NiceModal.Provider>
      </GlobalProvider>
    </ThemeProvider>
  </React.StrictMode>
);