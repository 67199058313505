import React from 'react';
import { Box, Typography, Grid, Chip, Badge, IconButton, CardContent, Card, Tooltip, Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import './EnquiryItem.css';
import { Lock, LockOpenOutlined, LockOutlined, Message, MessageOutlined } from '@mui/icons-material';
import NiceModal from '@ebay/nice-modal-react';

dayjs.extend(relativeTime);

const EnquiryItem = ({ enquiry, onMessageClick }) => {
  const isRegPlate = enquiry.Reg && enquiry.Reg !== 'CONSUMABLES';

  const today = dayjs().startOf('day');
  const createdDate = dayjs(enquiry.DatetimeCreated);
  const modifiedDate = dayjs(enquiry.DatetimeModified);
  const isCreatedToday = createdDate.isSame(today, 'day');
  const isModifiedToday = modifiedDate.isSame(today, 'day');

  const formattedCreatedDate = isCreatedToday
    ? createdDate.format('HH:mm')
    : createdDate.format('DD/MM/YYYY, HH:mm');
  const formattedModifiedDate = isModifiedToday
    ? modifiedDate.format('HH:mm')
    : modifiedDate.format('DD/MM/YYYY, HH:mm');

  const timeSinceCreated = createdDate.fromNow();
  const timeSinceModified = modifiedDate.fromNow();

  return (
    <Box sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', padding: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 1 }}>
            <StatusChip status={enquiry.Status} />
          </Box>
          <Box sx={{ paddingRight: 2 }}>
            <DocumentChip document={enquiry.Document} />
          </Box>
          {
            enquiry.Locked && (
              <Box sx={{ paddingRight: 2, color: 'orange' }}>
                <Tooltip title="This enquiry is locked.">
                  <LockOutlined />
                </Tooltip>
              </Box>
            )
          }
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            {enquiry.CustomerKeyCode + ' - ' + enquiry.GarageName}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MessagesIcon
            messageCount={enquiry.MessageCount}
            factorUnread={enquiry.FactorUnread}
            onMessageClick={() => NiceModal.show('enquiryDetailsModal', {
              initialTab: 1,
              enquiry,
            })}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 2 }}>
            {
              isRegPlate ? (
                <RegPlate size={1.05}>{enquiry.Reg}</RegPlate>
              ) : (
                <ConsumablesBox />
              )
            }
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h6" sx={{ lineHeight: 1 }}>{enquiry.Parts}</Typography>
            <Typography variant="caption">{enquiry.Notes || 'No additional notes provided.'}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 1 }}>
        <Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Created:</span> {formattedCreatedDate} ({timeSinceCreated})
            </Typography>
          </Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Modified: </span>
              {
                !!enquiry.DatetimeModified ? (
                  `${formattedModifiedDate} (${timeSinceModified})`
                ) : (
                  'Never'
                )
              }
            </Typography>
          </Box>
        </Box>

        <Box>
          {
            enquiry.Locked && (
              <Box sx={{ display: 'inline-block', paddingRight: 2 }}>
                <Button variant="text" startIcon={<LockOpenOutlined />}>
                  Unlock
                </Button>
              </Box>
            )
          }
          <Button variant="outlined" onClick={() => NiceModal.show('enquiryDetailsModal', { enquiry })}>
            View details
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export const StatusChip = ({ status }) => {
  const colors = {
    'Waiting': '#FFA500',
    'Ordered': '#28A745',
    'Quoted': 'dodgerblue',
    'GarageConfirmed': 'pink',
    'Cancelled': 'red'
  }

  const labels = {
    'Waiting': 'Quote',
    'Ordered': 'Ordered',
    'Quoted': 'Quoted',
    'GarageConfirmed': 'Garage Order',
    'Cancelled': 'Cancelled'
  }

  return (
    <Chip
      label={labels[status]}
      sx={{
        backgroundColor: colors[status] || '#28A745',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '0.9em',
        padding: '8px 0',
        width: '90px',
        userSelect: 'none',
      }}
    />
  )
}

export const DocumentChip = ({ document }) => {
  return (
    <Chip
      label={document}
      sx={{
        backgroundColor: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '0.9em',
        padding: '8px 0',
        width: '70px',
        border: '1px solid rgba(0,0,0,0.2)'
      }}
    />
  )
}

export const RegPlate = ({ children, size = 1 }) => {
  return (
    <div
      className="flex justify-center items-center rounded-md border border-black flex-0"
      style={{
        backgroundColor: 'rgb(248, 208, 56)',
        width: `${160 * size}px`,
        height: `${40 * size}px`,
        fontSize: `${27 * size}px`,
        fontFamily: 'LicensePlate',
        borderColor: 'hsl(47.5, 93.2%, 45%)',
        overflow: 'hidden'
      }}
    >
      {children}
    </div>
  )
}

export const ConsumablesBox = () => {
  return (
    <Box sx={{
      backgroundColor: '#444',
      borderRadius: '6px',
      textAlign: 'center',
      fontWeight: 600,
      color: '#FFFFFF',
      width: 168,
      height: 42,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      CONSUMABLES
    </Box>
  )
}

const MessagesIcon = ({ messageCount, factorUnread, onMessageClick }) => {
  const plural = messageCount !== 1

  return (
    messageCount > 0 && (
      <Tooltip title={`${messageCount} message${plural ? 's' : ''}, ${factorUnread} unread`}>
        {
          factorUnread > 0 ? (
            <Badge
              badgeContent={`${factorUnread}`}
              color="error"
            >
              <IconButton onClick={onMessageClick}>
                <MessageOutlined sx={{ color: 'rgba(0,0,0,0.5)' }} />
              </IconButton>
            </Badge>
          ) : (
            <IconButton onClick={onMessageClick}>
              <MessageOutlined sx={{ color: 'rgba(0,0,0,0.5)' }} />
            </IconButton>
          )
        }
      </Tooltip>
    )
  )
}

const EnquiryItemOld = ({ enquiry, onClick, onMessageClick, locked, lockedBy, currentUser }) => {
  const isRegPlate = enquiry.Reg && enquiry.Reg !== 'CONSUMABLES';

  const today = dayjs().startOf('day');
  const createdDate = dayjs(enquiry.DatetimeCreated);
  const modifiedDate = dayjs(enquiry.DatetimeModified);

  const isCreatedToday = createdDate.isSame(today, 'day');
  const isModifiedToday = modifiedDate.isSame(today, 'day');

  const formattedCreatedDate = isCreatedToday
    ? createdDate.format('HH:mm')
    : createdDate.format('DD/MM/YYYY, HH:mm');
  const formattedModifiedDate = isModifiedToday
    ? modifiedDate.format('HH:mm')
    : modifiedDate.format('DD/MM/YYYY, HH:mm');

  const timeSinceCreated = createdDate.fromNow();
  const timeSinceModified = modifiedDate.fromNow();
  const isLockedByCurrentUser = locked && lockedBy === currentUser; // Check if the current user locked it

  return (
    <Box sx={{
      mb: 2,
      p: 3,
      border: '1px solid #007BFF',
      borderRadius: '8px',
      backgroundColor: 'gray',
      color: '#fff',
      position: 'relative',
      overflow: 'visible'
    }} onClick={onClick}>

      {/* Only display this pill if the enquiry is locked */}
      {locked && !isLockedByCurrentUser && (
        <Box sx={{
          position: 'absolute',
          top: '-16px',
          left: '16px',
          backgroundColor: 'orange',
          color: '#fff',
          borderRadius: '20px',
          padding: '4px 12px',
          fontWeight: 'bold',
          fontSize: '0.9em',
          zIndex: 2
        }}>
          {lockedBy}
        </Box>
      )}

      <Grid container spacing={2}>
        {/* Status Label */}
        <Grid item xs={12} sm={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Chip sx={{ marginBottom: 1, fontSize: 18, fontWeight: 'bold', color: 'black', backgroundColor: 'white' }} label={enquiry.Document}>

          </Chip>
          <Chip
            label={enquiry.Status.toUpperCase()}
            sx={{
              backgroundColor: (() => {
                switch (enquiry.Status) {
                  case 'Waiting':
                    return '#FFA500'; // Orange
                  case 'Ordered':
                    return '#28A745'; // Green
                  case 'Quoted':
                    return 'dodgerblue'; // Dodger Blue
                  case 'GarageConfirmed':
                    return 'pink'; // Pink
                  case 'Cancelled':
                    return 'red'; // Red
                  default:
                    return '#28A745'; // Default to Green for any other status
                }
              })(),
              color: '#fff',
              fontWeight: 'bold',
              textAlign: 'center',
              borderRadius: '8px',
              width: '100%',
              fontSize: '0.9em',
              padding: '8px 0',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </Grid>

        {/* Main content */}
        <Grid item xs={12} sm={10}>
          {/* Registration Number and Garage */}
          <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={11} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '0px !important' }}>
              {isRegPlate ? (
                <div className="reg_plate_wrapper" style={{ background: 'none', border: 'none', width: '240px', padding: '0 12px' }}>
                  <div className="reg_plate reg_plate--back" style={{ border: 'none', margin: '0 auto' }}>{enquiry.Reg}</div>
                </div>
              ) : (
                <Box sx={{
                  backgroundColor: '#444',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#FFCC00',
                  fontFamily: 'Arial, sans-serif',
                  letterSpacing: '1px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Typography variant="h5">{enquiry.Reg}</Typography>
                </Box>
              )}
              <Typography
                variant="body1"
                sx={{ padding: 1, borderRadius: 5, fontSize: 25, ml: 2, fontWeight: 'bold', color: 'black', backgroundColor: 'white', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexGrow: 1, textAlign: 'center', fontFamily: 'Poppins, sans-serif', }}>
                {enquiry.CustomerKeyCode + ' - ' + enquiry.GarageName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label="messages" sx={{ color: '#fff' }} onClick={(e) => {
                e.stopPropagation(); // Prevent the enquiry click event
                onMessageClick(); // Trigger the message click event
              }}>
                {enquiry.MessageCount > 0 && (
                  <Badge
                    badgeContent={`${enquiry.FactorUnread}/${enquiry.MessageCount}`}
                    color={enquiry.FactorUnread > 0 ? "error" : "primary"}
                  >
                    <MailIcon />
                  </Badge>
                )}

              </IconButton>
            </Grid>
          </Grid>

          {/* Parts and Notes */}
          <Typography className="font-body" variant="body1" sx={{ fontFamily: 'Quicksand', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '4px' }}>
            {enquiry.Parts}
          </Typography>
          <Typography variant="body2" sx={{ color: 'lightgray', fontStyle: 'italic', fontFamily: 'Quicksand', textTransform: 'uppercase', }}>
            {enquiry.Notes || "No additional notes."}
          </Typography>
        </Grid>
      </Grid>

      {/* Created and Modified Dates */}
      <Grid container sx={{ mt: 2, borderTop: '1px solid #444', paddingTop: 1 }}>
        <Grid item xs={6}>
          <Typography variant="caption">
            Created: {formattedCreatedDate} - ({timeSinceCreated})
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <Typography variant="caption">
            Modified!!: sdf{enquiry.DatetimeModified}{
              !!enquiry.DatetimeModified ? (
                `${formattedModifiedDate} - (${timeSinceModified})`
              ) : (
                'Never'
              )
            }
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EnquiryItem;
