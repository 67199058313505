import Spinner from './gsys-ui/Spinner/Spinner'

const Loading = () => {
  return (
    <div className="flex fixed inset-0 min-h-screen">
      <div className="w-1/2 bg-blue-500" />
      <div className="w-1/2 bg-gradient-to-r from-blue-500 to-purple-600" />
      <div className="flex fixed inset-0 justify-center items-center">
        <Spinner size={60} color="rgba(255,255,255,0.4)" />
      </div>
    </div>
  )
}

export default Loading