import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Login from './components/Login';
import Charts from './components/Charts';
import Enquiries from './components/Enquiries';
import Customers from './components/Customers';
import Settings from './components/Settings';
import { Box, AppBar, Toolbar, IconButton, CssBaseline, Typography, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './components/Sidebar';

const MainApp = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/*" element={<Layout />}>
        <Route path="charts" element={<Charts />} />
        <Route path="enquiries" element={<Enquiries />} />
        <Route path="customers" element={<Customers />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (state) => {
    /*if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }*/
    if (state !== undefined) {
      setDrawerOpen(state)
    } else {
      setDrawerOpen(!drawerOpen);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={"https://www.melkshammotorspares.co.uk/images/mms-initials-logo-2019-500-002.png"}
            alt="Melksham Motor Spares Logo"
            style={{ width: '50px', marginRight: '15px' }}
          />
          <Typography variant="h6" noWrap>
            Connect+
          </Typography>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default' }}
      >
        <Box sx={{ p: 3, paddingTop: 10, paddingLeft: 11, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default MainApp;