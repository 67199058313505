import React, { useContext, useState } from 'react';
import { Box, Drawer as MuiDrawer, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RedeemIcon from '@mui/icons-material/Redeem';
import PeopleIcon from '@mui/icons-material/People';
import WorkIcon from '@mui/icons-material/Work';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings'; // Import the Settings icon
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import { BarChart } from '@mui/icons-material';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const Sidebar = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const { cinfo } = useContext(GlobalContext)
  console.log('cinfo', cinfo)

  const menuItems = [
    { text: 'Enquiries', icon: <QuestionAnswerIcon />, path: '/enquiries' },
    ...(
      cinfo.Admin === true ? (
        [{ text: 'Charts', icon: <BarChart />, path: '/charts' }]
      ) : (
        []
      )
    ),
    { text: 'Rewards', icon: <RedeemIcon />, path: '/rewards' },
    { text: 'Customers', icon: <PeopleIcon />, path: '/customers' },
    { text: 'Staff', icon: <WorkIcon />, path: '/staff' },
    { text: 'Reports', icon: <AssessmentIcon />, path: '/reports' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/settings' }, // Add the settings item
  ];

  return (
    <Drawer variant="permanent" open={isOpen}>
      <Box sx={{ paddingTop: 9, width: 240 }}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                navigate(item.path);
                toggleDrawer(false); // Close drawer on menu item click
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};


export default Sidebar;
