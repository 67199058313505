import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline,
  Grid,
  TextField,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalContext';
import Sidebar from './Sidebar';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import useApi from '../hooks/useApi';

dayjs.extend(relativeTime);

const Customer = () => {
  const [selectedGarage, setSelectedGarage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Search term state

  const { isLoading, data: customers, refetch } = useApi({
    url: 'https://vision-web-api-test.azurewebsites.net/api/connectplus/garageuserswithlicences'
  })

  if (isLoading) return null

  const handleOpenModal = (garage) => {
    setSelectedGarage(garage);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedGarage(null);
  };

  const getGarageLicenses = (garageName) => {
    const licenses = customers.filter(customer => customer.GarageName === garageName);
    const activeCount = licenses.filter(customer => customer.Active).length;
    const inactiveCount = licenses.length - activeCount;
    return { total: licenses.length, activeCount, inactiveCount };
  };

  const timeAgo = (date) => dayjs(date).fromNow();

  const handleRevokeLicense = (licenseId) => {
    console.log('Revoking license with ID:', licenseId);
    // Add API call to revoke license
  };

  const handleDeleteLicense = (licenseId) => {
    console.log('Deleting license with ID:', licenseId);
    // Add API call to delete license
  };

  // Filtered garages based on search term
  const filteredGarages = Array.from(new Set(customers.map(c => c.GarageName)))
    .filter(garageName => garageName.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Garage License Status
        </Typography>

        <TextField
          label="Search Garages"
          variant="outlined"
          fullWidth
          sx={{ mb: 3 }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
        />

        <Grid container spacing={3}>
          {filteredGarages.map(garageName => {
            const { total, activeCount, inactiveCount } = getGarageLicenses(garageName);

            return (
              <Grid item xs={12} sm={6} md={4} key={garageName}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '150px', // Standardized height
                  }}
                  onClick={() => handleOpenModal(garageName)}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                      {garageName}
                    </Typography>
                    <Typography color="textSecondary">
                      Total Licenses: {total}
                    </Typography>
                    <Typography color="textSecondary">
                      Active: {activeCount}
                    </Typography>
                    <Typography color="textSecondary">
                      Inactive: {inactiveCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        {selectedGarage && (
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={modalOpen}>
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
              }}>
                <Typography variant="h6" component="h2">
                  Licenses for {selectedGarage}
                </Typography>
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  {customers.filter(c => c.GarageName === selectedGarage).map(customer => (
                    <Grid item xs={12} sm={6} md={4} key={customer.GarageUserId}>
                      <Card sx={{ minHeight: '180px' }}>
                        <CardContent>
                          <Typography variant="h6">
                            {customer.GarageUserName}
                          </Typography>
                          <Typography color="textSecondary">
                            Last Use: {timeAgo(customer.LastUse)}
                          </Typography>
                          <Box sx={{ mt: 3 }}>
                            <Button
                              variant="contained"
                              color="error"
                              disabled={!customer.Active}
                              onClick={() => handleRevokeLicense(customer.DeviceLicenseId)}
                              sx={{ mr: 2 }}
                            >
                              Revoke License
                            </Button>
                            <Button
                              variant="contained"
                              disabled={customer.Active}
                              onClick={() => handleDeleteLicense(customer.DeviceLicenseId)}
                            >
                              Delete License
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
      </Container>
    </Box>
  );
};

export default Customer;
