import React, { useState, useEffect, useContext } from 'react';
import { Box, AppBar, Toolbar, IconButton, CssBaseline, Typography, FormControl, Select, MenuItem, Grid, Card, CardContent, InputLabel, CardHeader } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { GlobalContext } from '../context/GlobalContext';
import Sidebar from './Sidebar';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Filler,
} from 'chart.js';
import useApi from '../hooks/useApi';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Filler
);

const getStartDate = (viewType) => {
  if (viewType === 'day') {
    return moment().startOf('day').toDate()
  } else if (viewType === 'week') {
    return moment().startOf('week').toDate()
  } else if (viewType === 'month') {
    return moment().startOf('month').toDate()
  }

  return null
}

const groupByTimePeriod = (enquiries, viewType) => {
  const formatter = {
    day: (date) => `${new Date(date).getHours()}:00`,
    week: (date) => new Date(date).toLocaleDateString('en-GB', { weekday: 'long' }),
    month: (date) => {
      const d = new Date(date);
      const weekNumber = Math.ceil(d.getDate() / 7);
      return `Week ${weekNumber}`;
    },
  };

  return enquiries.reduce((acc, enquiry) => {
    const timeKey = formatter[viewType](enquiry.DatetimeModified);
    acc[timeKey] = acc[timeKey] || { total: 0, ordered: 0, quoted: 0, waiting: 0 };
    acc[timeKey].total += 1;
    if (enquiry.Status === 'Ordered' || enquiry.Status === 'Order') {
      acc[timeKey].ordered += 1;
    }
    if (enquiry.Status === 'Quoted') {
      acc[timeKey].quoted += 1;
    }
    if (enquiry.Status === 'Waiting') {
      acc[timeKey].waiting += 1;
    }
    return acc;
  }, {});
};

const Charts = () => {
  const [branch, setBranch] = useState('All');
  const [view, setView] = useState('day')
  const startDate = getStartDate(view).toISOString();
  const endDate = moment().endOf('day').toDate().toISOString()

  const { isLoading, isFetching, data: enquiries, refetch } = useApi({
    url: 'https://api.aa-vision.com/api/connectplus/factor/enquiries/',
    method: 'post',
    data: { StartDate: startDate, EndDate: endDate },
    refetch: 60 * 1000
  })

  console.log('enquiries', enquiries, startDate, endDate)

  if (isLoading) return null

  const filteredEnquiries = branch && branch !== 'All' ? (
    enquiries.filter((enquiry) => enquiry.Branch === branch)
  ) : (
    enquiries
  )

  const uniqueBranches = ['All', ...new Set(enquiries.map((enquiry) => enquiry.Branch))];
  const groupedEnquiries = groupByTimePeriod(filteredEnquiries, view);

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 4, alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4" gutterBottom>Home Page</Typography>
        </Box>
        <Box sx={{ flex: 'none', display: 'flex' }}>
          <Box sx={{ width: 200, marginRight: 2 }} >
            <FormControl fullWidth>
              <InputLabel id="branch-label">Branch</InputLabel>
              <Select
                labelId="branch-label"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                label="Branch"
              >
                {uniqueBranches.map((branch) => (
                  <MenuItem key={branch} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ width: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="view-label">View</InputLabel>
              <Select
                labelId="view-label"
                value={view}
                onChange={(e) => setView(e.target.value)}
                label="View"
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Conversion Rate</Typography>
              <ConversionsChart groupedEnquiries={groupedEnquiries} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Daily Enquiries</Typography>
              <DailyEnquiriesChart view={view} groupedEnquiries={groupedEnquiries} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Enquiries by Status</Typography>
              <StatusChart groupedEnquiries={groupedEnquiries} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Ordered Enquiries</Typography>
              <OrderedEnquiriesChart groupedEnquiries={groupedEnquiries} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Enquiries Table</Typography>
              <Box sx={{ height: 400 }}>
                <DataGrid
                  rows={filteredEnquiries.map((enquiry, index) => ({ ...enquiry, id: index }))}
                  columns={[
                    { field: 'CustomerKeyCode', headerName: 'Customer Key Code', width: 200 },
                    { field: 'Status', headerName: 'Status', width: 120 },
                    { field: 'Branch', headerName: 'Branch', width: 120 },
                    { field: 'Username', headerName: 'Username', width: 120 },
                    { field: 'TotalCost', headerName: 'Total Cost', width: 110 },
                  ]}
                  pageSize={5}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

const ConversionsChart = ({ groupedEnquiries }) => {
  const conversionRateData = {
    labels: Object.keys(groupedEnquiries),
    datasets: [
      {
        label: 'Conversion Rate',
        data: Object.values(groupedEnquiries).map(group => (group.ordered / group.total) * 100 || 0),
        borderColor: 'rgb(2, 143, 196)',
        fill: true,
        backgroundColor: 'rgba(2, 143, 196, 0.1)',
        tension: 0.2,
        borderWidth: 4,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHitRadius: 10,
        pointBackgroundColor: 'rgb(2, 143, 196)',
        pointBorderColor: 'rgb(2, 143, 196)'
      }
    ],
  };

  return (
    <Line
      data={conversionRateData}
      options={{
        scales: {
          x: { grid: { display: false } },
          y: {
            beginAtZero: true,
            max: 100,
            ticks: {
              callback: (value, index, values) => `${value}%`,
              stepSize: 10,
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />
  )
}

const DailyEnquiriesChart = ({ view, groupedEnquiries }) => {
  const dailyEnquiriesData = {
    labels: Object.keys(groupedEnquiries),
    datasets: [
      {
        label: `Total Enquiries (${view})`,
        data: Object.values(groupedEnquiries).map(group => group.total),
        borderColor: 'rgb(2, 143, 196)',
        fill: true,
        backgroundColor: 'rgba(2, 143, 196, 0.1)',
        tension: 0.2,
        borderWidth: 4,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHitRadius: 10,
        pointBackgroundColor: 'rgb(2, 143, 196)',
        pointBorderColor: 'rgb(2, 143, 196)'
      },
    ],
  };

  return (
    <Line
      data={dailyEnquiriesData}
      options={{
        scales: {
          x: { grid: { display: false } },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) { if (value % 1 === 0) { return value; } }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />
  )
}

const StatusChart = ({ groupedEnquiries }) => {
  const statusChartData = {
    labels: Object.keys(groupedEnquiries),
    datasets: [
      {
        label: 'Ordered',
        data: Object.values(groupedEnquiries).map(group => group.ordered),
        borderColor: 'rgb(75, 192, 192)',
        fill: false,
        tension: 0.2,
        borderWidth: 4,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHitRadius: 10,
        pointBackgroundColor: 'rgb(75, 192, 192)',
        pointBorderColor: 'rgb(75, 192, 192)'
      },
      {
        label: 'Quoted',
        data: Object.values(groupedEnquiries).map(group => group.quoted),
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        tension: 0.2,
        borderWidth: 4,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHitRadius: 10,
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: 'rgb(54, 162, 235)'
      },
      {
        label: 'Waiting',
        data: Object.values(groupedEnquiries).map(group => group.waiting),
        fill: false,
        borderColor: 'rgb(255, 205, 86)',
        tension: 0.2,
        borderWidth: 4,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHitRadius: 10,
        pointBackgroundColor: 'rgb(255, 205, 86)',
        pointBorderColor: 'rgb(255, 205, 86)'
      },
    ],
  };

  return (
    <Line data={statusChartData} />
  )
}

const OrderedEnquiriesChart = ({ groupedEnquiries }) => {
  const pieChartData = {
    labels: Object.keys(groupedEnquiries),
    datasets: [
      {
        data: Object.values(groupedEnquiries).map(group => group.ordered),
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(255, 205, 86, 0.6)', 'rgba(54, 162, 235, 0.6)'],
        hoverBackgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 205, 86, 1)', 'rgba(54, 162, 235, 1)'],
      },
    ],
  };

  return (
    <Pie data={pieChartData} />
  )
}

export default Charts;
