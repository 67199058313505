import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [apir, setApir] = useState('');
  const [cinfo, setCinfo] = useState('');
  const [loginError, setLoginError] = useState(null)
  const baseUrl = 'https://vision-web-api-test.azurewebsites.net'

  return (
    <GlobalContext.Provider
      value={{
        apir, setApir,
        cinfo, setCinfo,
        username, setUsername,
        password, setPassword,
        loginError, setLoginError,
        baseUrl
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
