import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useModal } from '@ebay/nice-modal-react'
import s from './ModalContainer.module.scss'
import { motion, AnimatePresence, MotionConfig } from 'framer-motion'
//mport { GlobalContext } from '@/context/GlobalContext'

const ModalContainer = forwardRef(({ children }, ref) => {
  //const { events } = useRouter()
  const containerRef = useRef(null)
  //const { setScrollLock } = useContext(GlobalContext)
  const modal = useModal()
  const [isClosing, setClosing] = useState(false)

  useImperativeHandle(ref, () => ({
    closeModal: () => setClosing(true)
  }))

  const handleOnClick = (e) => {
    if (e.target !== containerRef.current) return
    //setScrollLock(false)
    setClosing(true)
  }

  const handleExitComplete = () => {
    //setScrollLock(false)
    modal.remove()
  }

  useEffect(() => {
    //setScrollLock(true)
  }, [])

  /*useEffect(() => {
    const closeModal = () => setClosing(true)
    events.on('routeChangeStart', closeModal)

    return () => {
      events.off('routeChangeStart', closeModal)
    }
  }, [events])*/

  return (
    <MotionConfig transition={{
      duration: 0.28,
      ease: [0.4, 0, 0.2, 1]
    }}>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {
          !isClosing && (
            <motion.div
              className={s.modalContainer}
              ref={containerRef}
              onClick={handleOnClick}
              initial={{ background: 'rgba(0,0,0,0)' }}
              animate={{ background: 'rgba(0,0,0,0.3)' }}
              exit={{ background: 'rgba(0,0,0,0)' }}
            >
              {children}
            </motion.div>
          )
        }
      </AnimatePresence>
    </MotionConfig>
  )
})

export const ModalContainerAnimateDown = forwardRef(({ children }, ref) => {
  return (
    <ModalContainer ref={ref}>
      <motion.div
        initial={{ transform: 'translateY(-50px)', opacity: 0 }}
        animate={{ transform: 'translateY(0px)', opacity: 1 }}
        exit={{ transform: 'translateY(150px)', opacity: 0 }}
      >
        {children}
      </motion.div>
    </ModalContainer>
  )
})

export default ModalContainer