import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalContext';
import logo from '../images/logo.png';
import bgImage from '../images/bg.png';
import { getToken } from '../lib/haxios';

const Login = () => {
  const { username, setUsername, password, setPassword, loginError } = useContext(GlobalContext);

  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, [setUsername, setPassword]);

  const tryLogin = async () => {
    try {
      setErrorMessage('')
      const res = await getToken(username, password)

      if (!res) {
        setErrorMessage('Invalid username or password.')
        return
      }

      navigate('/enquiries')
    } catch (error) {
      console.error('Error during login:', error.message);
      setErrorMessage('Error during login. Please try again.');
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left side - Background Image */}
      <div
        className="hidden w-1/2 bg-center bg-cover md:block"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>

      {/* Right side - Login form */}
      <div className="flex justify-center items-center p-8 w-full bg-gradient-to-r from-blue-500 to-purple-600 md:w-1/2">
        <div className="w-full max-w-md">
          <div className="flex justify-center mb-8">
            <img src={logo} alt="Logo" className="object-contain h-16" />
          </div>
          <form className="space-y-6">
            <div>
              <div className="flex items-center py-2 border-b border-gray-300">
                <span className="mr-3 text-white material-icons">person</span>
                <input
                  className="px-2 py-1 w-full leading-tight placeholder-gray-300 text-white bg-transparent border-none appearance-none focus:outline-none"
                  id="username"
                  type="text"
                  placeholder="Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center py-2 border-b border-gray-300">
                <span className="mr-3 text-white material-icons">lock</span>
                <input
                  className="px-2 py-1 w-full leading-tight placeholder-gray-300 text-white bg-transparent border-none appearance-none focus:outline-none"
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <label className="flex items-center text-sm text-white">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                Remember me
              </label>
              <a href="#" className="text-sm text-blue-200 hover:underline">
                Forget Password?
              </a>
            </div>
            {errorMessage && (
              <div className="mb-4 text-sm text-red-500">{errorMessage}</div>
            )}
            {loginError && (
              <div className="mb-4 text-sm text-red-500">{loginError}</div>
            )}
            <div>
              <button
                className="px-4 py-2 w-full font-bold text-white bg-blue-600 rounded-lg shadow-lg transition-all duration-300 hover:bg-blue-700"
                type="button"
                onClick={tryLogin}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
