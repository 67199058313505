import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import MainApp from './MainApp';
import { GlobalContext } from './context/GlobalContext';
import useAuth from './hooks/useAuth';
import { getApir, getCinfo } from './lib/haxios';
import Loading from './components/Loading';
import './lib/modals'

function App() {
  const navigate = useNavigate()
  const { setCinfo, setLoginError } = useContext(GlobalContext)
  const [isLoading, setLoading] = useState(true)
  const { isAuthed } = useAuth()

  useEffect(() => {
    console.log('isAuthed', isAuthed);
    if (isAuthed === null) return;

    if (isAuthed === false) {
      navigate('/');
      setLoading(false);
      return;
    }

    (async () => {
      const cinfo = await getCinfo();

      if (!cinfo) {
        navigate('/');
        setLoading(false);
        return;
      }

      try {
        const apirUrl = await getApir();
        if (!apirUrl) {
          throw new Error("APIR URL not loaded properly");
        }

        setCinfo(cinfo);
        console.info('cinfo', cinfo);
        if (window.location.pathname === '/') navigate('/enquiries');
      } catch (e) {
        setLoginError(e.message);
        navigate('/');
      } finally {
        setLoading(false);
      }
    })();
  }, [isAuthed]);


  if (isLoading) {
    return <Loading />
  }

  return (
    <MainApp />
  );
}

export default App
