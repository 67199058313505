import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Badge,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalContext';
import dayjs from 'dayjs';
import EnquiryItem from './EnquiryItem';
import useApi from '../hooks/useApi';
import haxios from '../lib/haxios'
import { Refresh } from '@mui/icons-material';
import { motion } from 'framer-motion'

const Enquiries = () => {
  const { token, username } = useContext(GlobalContext);
  const [startDate, setStartDate] = useState(dayjs().startOf('week').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().endOf('week').format('YYYY-MM-DD'));
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [branchFilter, setBranchFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const [statusFilters, setStatusFilters] = useState({
    FactorUnread: true,
    GarageConfirmed: true,
    Waiting: true,
    Ordered: false,
    Quoted: false,
    Cancelled: false
  });

  const longPressTimer = useRef(null);
  const isLongPress = useRef(false);

  // Status label mapping
  const statusLabels = {
    FactorUnread: 'Unread Messages',
    GarageConfirmed: 'Garage Order',
    Waiting: 'Quote',
    Ordered: 'Ordered',
    Quoted: 'Quoted',
    Cancelled: 'Cancelled'
  };

  const { isLoading, isFetching, data: enquiries, refetch } = useApi({
    url: 'https://api.aa-vision.com/api/connectplus/factor/enquiries/',
    method: 'post',
    data: { StartDate: startDate, EndDate: endDate },
    refetch: 20 * 1000
  })

  if (isLoading) return null

  const handleEnquiryClick = async (enquiry, tabIndex = 0) => {
    console.log('handleEnquiryClick', enquiry, tabIndex)
    try {
      await haxios.get(`https://vision-web-api-test.azurewebsites.net/api/connectplus/factor/enquiry/lock/${enquiry.EnquiryId}`);
      setSelectedEnquiry(enquiry);
      setSelectedTabIndex(tabIndex);
    } catch (error) {
      console.error('Error locking enquiry:', error);
    }
  };

  const handleCloseDetailsView = async () => {
    if (selectedEnquiry && selectedEnquiry.Locked) {
      if (selectedEnquiry.LockedUsername === username) {
        try {
          await axios.get(`https://vision-web-api-test.azurewebsites.net/api/connectplus/factor/enquiry/unlock/${selectedEnquiry.EnquiryId}`, {
            headers: { Token: token },
          });
        } catch (error) {
          console.error('Error unlocking enquiry:', error);
        }
      } else {
        console.log('Cannot unlock: Enquiry is locked by another user');
      }
    }

    setSelectedEnquiry(null);
    setSelectedTabIndex(0);
  };

  const toggleStatusFilter = (status) => {
    setStatusFilters(prev => ({ ...prev, [status]: !prev[status] }));
  };

  const branches = [...new Set(enquiries.map((enquiry) => enquiry.Branch))];
  const statuses = [...new Set(enquiries.map((enquiry) => enquiry.Status))];

  const getStatusCounts = () => {
    const statusCounts = {
      FactorUnread: 0,
      GarageConfirmed: 0,
      Waiting: 0,
      Ordered: 0,
      Quoted: 0,
      Cancelled: 0
    };

    enquiries.forEach(enquiry => {
      if ((enquiry.FactorUnread || 0) > 0) statusCounts.FactorUnread++;
      if (enquiry.Status === 'GarageConfirmed') statusCounts.GarageConfirmed++;
      if (enquiry.Status === 'Waiting') statusCounts.Waiting++;
      if (enquiry.Status === 'Ordered') statusCounts.Ordered++;
      if (enquiry.Status === 'Quoted') statusCounts.Quoted++;
      if (enquiry.Status === 'Cancelled') statusCounts.Cancelled++;
    });

    return statusCounts;
  };

  const statusCounts = getStatusCounts();

  const filteredEnquiries = enquiries
    .filter((enquiry) => {
      if (Object.values(statusFilters).every(v => !v)) return false;

      const matchesBranch = !branchFilter || enquiry.Branch?.toLowerCase() === branchFilter.toLowerCase();
      const matchesStatus = !statusFilter || enquiry.Status?.toLowerCase() === statusFilter.toLowerCase();

      const customerName = enquiry.GarageName || '';
      const vehicleReg = enquiry.Reg || '';
      const parts = enquiry.Parts || '';
      const notes = enquiry.Notes || '';
      const garageAcctNo = enquiry.CustomerKeyCode || '';
      const garageName = enquiry.GarageName || '';

      const matchesSearch = customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vehicleReg.toLowerCase().includes(searchTerm.toLowerCase()) ||
        parts.toLowerCase().includes(searchTerm.toLowerCase()) ||
        notes.toLowerCase().includes(searchTerm.toLowerCase()) ||
        garageAcctNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        garageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enquiry.Status?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesStatusFilters = (
        (statusFilters.FactorUnread && (enquiry.FactorUnread || 0) > 0) ||
        (statusFilters.GarageConfirmed && enquiry.Status === 'GarageConfirmed') ||
        (statusFilters.Waiting && enquiry.Status === 'Waiting') ||
        (statusFilters.Ordered && enquiry.Status === 'Ordered') ||
        (statusFilters.Quoted && enquiry.Status === 'Quoted') ||
        (statusFilters.Cancelled && enquiry.Status === 'Cancelled')
      );

      return matchesBranch && matchesStatus && matchesSearch && matchesStatusFilters;
    })
    .sort((a, b) => {
      const aPriority = (a.Status === 'Waiting' || a.Status === 'GarageConfirmed' || (a.FactorUnread || 0) > 0);
      const bPriority = (b.Status === 'Waiting' || b.Status === 'GarageConfirmed' || (b.FactorUnread || 0) > 0);

      if (aPriority === bPriority) {
        const aDate = new Date(a.Modified || a.Created);
        const bDate = new Date(b.Modified || b.Created);
        return bDate - aDate;
      }

      return aPriority ? -1 : 1;
    });

  console.log('isFetching', isFetching)

  return (
    <Grid container spacing={3} sx={{ height: '100%' }}>
      <Grid item xs={3}>
        <Box sx={{}} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ lineHeight: 1 }}>Search enquiries</Typography>
                <Tooltip
                  title="Refresh enquiries"
                  placement="top"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10]
                          }
                        }
                      ]
                    }
                  }}
                >
                  <Box>
                    <motion.div
                      animate={{ rotate: isFetching ? [0, 360] : 360 }}
                      transition={{
                        duration: 1,
                        repeat: isFetching ? Infinity : 0
                      }}
                    >
                      <IconButton color="primary" onClick={refetch}>
                        <Refresh />
                      </IconButton>
                    </motion.div>
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                label="Type to search..."
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  labelId="branch-label"
                  value={branchFilter}
                  onChange={(e) => setBranchFilter(e.target.value)}
                  label="Branch"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {branches.map((branch) => (
                    <MenuItem key={branch} value={branch}>
                      {branch}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ paddingTop: 2 }}>
            <Box sx={{ paddingBottom: 1 }}>
              <Typography variant="h6">Filter by status</Typography>
            </Box>
            {
              Object.entries(statusFilters).map(([status, isActive]) => (
                <Box key={status} sx={{ paddingBottom: 2 }}>
                  <Badge
                    badgeContent={statusCounts[status]}
                    color="primary"
                  >
                    <Button
                      variant={isActive ? "contained" : "outlined"}
                      color={isActive ? 'success' : 'primary'}
                      onClick={() => toggleStatusFilter(status)}
                    >
                      {statusLabels[status]}
                    </Button>
                  </Badge>
                </Box>
              ))
            }
          </Box>
        </Box>
      </Grid>

      <Grid item xs={9} style={{ maxHeight: '100%', overflow: 'auto' }}>
        {filteredEnquiries.length > 0 ? (
          filteredEnquiries.map((enquiry, index) => (
            <EnquiryItem
              key={enquiry.Document}
              enquiry={enquiry}
              locked={enquiry.Locked}
              lockedBy={enquiry.LockedUsername}
              currentUser={username}
              onClick={() => handleEnquiryClick(enquiry)}
              onMessageClick={() => handleEnquiryClick(enquiry, 1)}
            />
          ))
        ) : (
          <Typography variant="body2">No enquiries found.</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Enquiries;
